$black: #000;
$darkgrey: #333;
$mediumgrey: #ccc;
$lightgrey: #eee;
$white: #fff;

$primary: #113f8c;
$secondary: #2c4f7b;
$tertiary: #84baf3;
$dark: #1c1d28;

$positive: #7ED648;
$negative: #e64747;
$neutral: #ffe06f;

$button-primary: #335996;
$button-secondary: #fecb37;
$button-positive: #3ebe3b;
$button-negative: #e64747;