@import 'partials/_colours.scss';

form {
    text-align: center;

    *:focus {
        outline: none;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    fieldset {
        margin: 0 0 1rem;
        padding: 0;
        border: 0 none;

        legend {
            margin: 0 0 0.5rem;
            font-weight: 100;
            font-size: 150%;
        }

        &.inline {
            label {
                display: inline-block;
            }

            input {
                display: inline-block;
                clear: none;
            }
        }

        &.recaptcha {
            width: 100%;
            height: 78px;
            margin-bottom: 0;
        }

        &.buttons {
            float: left;
            width: 100%;
            margin: 2rem 0 0;
            clear: both;

            button,
            a.button {
                margin: 0 0.625rem;
            }
        }
    }

    .radiochecks {
        padding: 0 0.5rem;

        &.inline {
            text-align: left;

            label {
                display: inline-block;
                margin: 1rem 1rem 0.25rem;
            }
        }

        label {
            position: relative;
            clear: left;
        }

        input {
            float: left;
            display: inline-block;
            width: auto;
            margin: 0.125rem 0.25rem 0.125rem 0;
        }

        span {
            display: block;
            padding-left: 1.25rem;
        }
    }

    p + label,
    input + label,
    select + label,
    textarea + label,
    fieldset + label,
    .optional + label,
    span.error + label,
    .half + label,
    span.error + .recaptcha,
    .selectize-control + label {
        margin-top: 1rem;
    }

    label {
        display: block;
        margin: 1rem 0 0.25rem 0;
        text-transform: none;
        text-align: left;
        font-size: 90%;
        clear: left;

        &.req:after {
            content: " *";
        }
    }

    input {
        display: block;
        width: 100%;
        padding: 0.5rem 0.6rem;
        border: 1px solid $mediumgrey;
        border-radius: 3px;
        font-size: 90%;
        clear: left;

        &[type="text"] {
            -webkit-appearance: none;
        }

        &.datepicker {
            text-align: left;
        }

        &:focus {
            border-color: lighten($secondary, 20%);
        }

        &.amount {
            position: relative;
            text-align: right;

            &::before {
                content: '$';
                position: absolute;
                left: 0;
            }
        }
    }

    textarea {
        display: block;
        width: 100%;
        padding: 0.5rem 0.6rem;
        border: 1px solid $mediumgrey;
        border-radius: 3px;
        font-size: 90%;
        clear: left;

        &:focus {
            border-color: lighten($secondary, 20%);
        }
    }

    select {
        display: block;
        background: $white url('/img/arrow.gif') no-repeat;
        background-position: right 0.75rem top 50%;
        width: 100%;
        padding: 0.5rem 2.3rem 0.5rem 0.6rem;
        text-overflow: ellipsis;
        border: 1px solid $mediumgrey;
        border-radius: 2px;
        font-size: 90%;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        clear: left;

        &[disabled],
        &[readonly] {
            background-image: url('/img/arrow-disabled.gif');
        }

        &:focus {
            border-color: lighten($secondary, 20%);
        }
    }

    select::-ms-expand {
        display: none;
    }

    input[disabled],
    select[disabled],
    textarea[disabled] {
        border-color: $lightgrey;
    }

    input[readonly],
    select[readonly],
    textarea[readonly] {
        border-color: $lightgrey;
    }

    .nav {
        margin: 0.75rem 1rem 0.75rem 0.25rem;
        font-size: 70%;

        a {
            text-decoration: none;
            color: $darkgrey;
        }
    }

    button,
    a.button {
        margin-top: 1rem;
    }

    p + label {
        margin-top: 0;
    }
}

.ui-datepicker {
    select {
        &.ui-datepicker-month,
        &.ui-datepicker-year {
            background: $white;
            font-weight: 400;
            color: $black;
        }
    }
}

.upload {
    margin: 0 1rem;

    label {
        background-color: $white;
        width: 100%;
        margin: 2rem 0;
        padding: 2rem;
        text-align: center;
        border: 1px dashed #999;
        border-radius: 10px;
    }

    &:hover {
        label {
            background-color: $lightgrey;
            cursor: pointer;
        }
    }
}

/* Errors */

input.error,
select.error,
textarea.error {
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 0 none;
    border-color: $negative;

    &:focus {
        border-color: $negative;
    }
}

.radiochecks {
    span.error {
        border-radius: 3px;
    }
}

fieldset + span.error {
    border-radius: 3px;
}

// #start_date-error {
//     width: 11.25rem;
//     margin-left: 10.5rem;
// }

// #end_date-error {
//     width: 11.25rem;
//     margin-left: 10.5rem;
// }