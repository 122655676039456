@import 'partials/_colours.scss';

.message {
    margin-bottom: 2rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 2px solid;
    text-align: center;
    font-size: 90%;
    clear: both;

    * {
        color: $white;
    }

    h3 {
        margin-top: -0.25rem;
        margin-bottom: 0.25em;
    }

    p {
        margin: 0 auto;
        color: $white;
    }

    a {
        color: $white;
    }

    &.negative {
        background: $negative;
        border-color: $negative;
    }

    &.positive {
        background: $positive;
        border-color: $positive;
    }

    &.neutral {
        background: $secondary;
        border-color: $secondary;
    }
}

.site-messages {
    position: absolute;
    top: 0; right: 0; left: 0;
    z-index: 3;

    .message {
        margin-bottom: 0;
        border-radius: 0;
    }
}

span.error {
    display: block;
    background: $negative;
    width: 100%;
    margin: 0;
    padding: 0.4rem 0.65rem;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    text-align: left;
    font-size: 70%;
    color: $white;

    &:empty {
        display: none;
    }
}

p.error {
    text-align: center;
    color: $negative;
}