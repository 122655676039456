@import 'partials/_colours.scss';

* {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
}

html,
body {
    font-family: 'Avenir', 'Nunito Sans', sans-serif;
    color: $dark;
}

h1 {
    display: block;
    width: 100%;
    margin: 0 0 1em;
    text-align: center;
    font-weight: 300;
    font-size: 200%;
}

h2 {
    display: block;
    width: 100%;
    margin: 0 0 0.5em;
    font-weight: 300;
    font-size: 180%;
}

h3 {
    display: block;
    width: 100%;
    margin: 0 0 0.5em;
    font-weight: 300;
    font-size: 160%;
}

h4 {
    display: block;
    width: 100%;
    margin: 0 0 0.5em;
    font-weight: 300;
    font-size: 140%;
}

h5 {
    display: block;
    width: 100%;
    margin: 0 0 0.5em;
    font-weight: 300;
    font-size: 120%;
}

h6 {
    display: block;
    width: 100%;
    margin: 0 0 0.5em;
    font-weight: 500;
    font-size: 100%;
}

p {
    margin: 0 0 1em;
    font-size: 100%;
}

ul {
    margin: 0 0 1em;
    padding-left: 1.5em;
    list-style: none;

    li {
        margin-bottom: 0;
        list-style-type: disc;
    }

    ul {
        margin-bottom: 0;
    }
}

ol {
    margin: 0 0 1em;
    padding-left: 1.5em;

    li {
        margin-bottom: 0;
    }

    ol {
        margin-bottom: 0;
    }
}

a {
    position: relative;
    text-decoration: none;
    color: $primary;

    &:hover {
        color: $secondary;
    }

    &.negative {
        color: $negative;

        &:hover {
            color: darken($negative, 10%);
        }
    }
}

strong {
    font-weight: bold;
}