@import 'partials/_colours.scss';

button,
a.button {
    display: inline-block;
    background: $dark;
    padding: 0.5rem 1.25rem;
    border: 1px solid $dark;
    border-radius: 3px;
    text-decoration: none;
    text-align: center;
    text-shadow: none;
    font-size: 90%;
    color: $white;

    &:after {
        content: none;
    }

    &:hover {
        background: $secondary;
        border-color: $dark;
        color: $white;
    }

    &.positive {
        background: $button-positive;
        border: 0 none;
        color: $white;

        &:hover {
            background: darken($button-positive, 10%);
        }
    }

    &.negative {
        background: $button-negative;
        border: 0 none;
        color: $white;

        &:hover {
            background: darken($button-negative, 10%);
        }
    }

    &.slim {
        padding: 0.25rem 1rem;
    }

    &.outline {
        background-color: transparent;
        border: 1px solid $dark;
        color: $dark;

        &:hover {
            background-color: $dark;
            color: $white;
        }
    }

    &[disabled] {
        background: $mediumgrey;

        &:hover {
            background: $mediumgrey;
            cursor: not-allowed;
        }
    }
}

.buttons {
    button,
    a.button {
        margin-left: 1.25rem;
    }

    &.right {
        text-align: right;

        button,
        a.button {
            margin-right: 0;
            margin-left: 1.5rem;
        }
    }
}

.actions {
    button,
    a.button {
        float: right;
        margin-top: 0;
    }
}