@import 'neat';
@import 'partials/_grids.scss';

@import 'partials/_colours.scss';
@import 'partials/_typography.scss';
@import 'partials/_buttons.scss';
@import 'partials/_forms.scss';
@import 'partials/_messages.scss';

.clearfix:before,
.clearfix:after {
    content: '';
    display: table;
    clear: both;
}

.centraliser {
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
}

body {
    background: $white;
}

#pagecontainer {
    position: relative;
}

#sidebar {
    position: fixed;
    background: $dark;
    top: 0; bottom: 0; left: 0;
    width: 25%;
}

.full {
    @include grid-column(12);

    .full {
        @include grid-column(12, $gutterless);
    }

    .half {
        @include grid-column(6, $gutterless);
    }

    .third {
        @include grid-column(4, $gutterless);
    }
}

.half {
    @include grid-column(6);
}

.third {
    @include grid-column(4);
}


// Header

header {
    position: relative;
    background: $tertiary;
    min-height: 3.75rem;
    text-align: center;

    #logo {
        display: block;
        position: absolute;
        top: 50%; left: 50%;
        width: 6.25rem;
        margin: 0 auto;
        transform: translate(-50%, -50%);

        img {
            display: block;
            width: 100%;
        }
    }

    #account-icon {
        float: right;
        display: none;
        position: relative;
        left: 1rem;
        width: 3.75rem;
        height: 3.25rem;
        margin: 0.125rem;
        padding: 0.75rem 0.875rem;
        border-radius: 0.25rem;
        transition: all 300ms ease 0ms;
        transform: scale(.75);
        color: $dark;

        &.is-active {
            background: $dark;
            color: $white;
        }

        i {
            font-size: 1.75em;
        }
    }

    #menutoggle {
        float: right;
        display: none;
        margin: 0.125rem;
        transition: all 300ms ease 0ms;
        transform: scale(.75);

        &:focus {
            outline: none;
        }

        &:hover {
            background: transparent;
            opacity: 1;
        }

        .hamburger-box {
            width: 1.875rem;
            height: 0.75rem;
        }

        &.is-active {
            background: $dark;

            .hamburger-box {
                width: 1.875rem;
                height: 0.75rem;
            }

            .hamburger-inner,
            .hamburger-inner:after,
            .hamburger-inner:before {
                background: $white;
                width: 1.875rem;
                height: 0.1875rem;
            }
        }
    }

    .hamburger-inner {
        margin-top: -1px;
    }

    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
        background: $dark;
        width: 1.875rem;
        height: 0.1875rem;
    }

    .hamburger-inner:before {
        top: -0.5rem;
    }

    .hamburger-inner:after {
        bottom: -0.5rem;
    }
}


// Nav

#sidebar {
    nav {
        &.is-active {
            transform: translateY(0);
        }
    }
}

nav {
    position: absolute;
    top: 3.75rem; right: 0; bottom: 0; left: 0;
    overflow-y: auto;
    clear: left;

    ul {
        margin: 1rem 0;
        padding: 0;

        li {
            list-style: none;

            &.hide {
                i {
                    transform: rotate(-180deg);
                }
            }

            i {
                float: right;
                margin: 0.1875rem;
                transition: .3s all;
                color: $white;
            }

            a {
                display: block;
                padding: 0.5em 1.5em;
                font-size: 90%;
                color: $white;

                &:hover {
                    color: $tertiary;
                }
            }

            ul {
                margin: 0 0 1rem;

                li {
                    a {
                        padding-left: 2.5em;
                    }

                    ul {
                        display: none;
                    }
                }
            }
        }
    }
}


// User

#user {
    position: fixed;
    background: $tertiary;
    top: 0; right: 0; left: 0;
    height: 3.75rem;
    margin-left: 25%;
    padding: 1rem;
    text-align: right;
    font-size: 90%;
    z-index: 2;

    &.is-active {
        transform: translateY(0);
    }

    p {
        display: inline-block;
        margin: 0.25rem 0;

        strong {
            margin-left: 2rem;
        }
    }

    .logout {
        float: right;
        margin: 0.125rem 0 0.125rem 2rem;
        font-size: 90%;
    }
}


// Announcement

#announcement {
    position: relative;
    background: $lightgrey;
    right: 0; left: 0;
    margin: 3.75rem 0 0 25%;
    padding: 1rem 1.25rem 0;
    text-align: center;
    font-size: 90%;
    color: $darkgrey;

    &.notification {
        background: $neutral;
        color: darken(#fecb37, 25%);
    }

    &.alert {
        background: lighten($negative, 35%);
        border-color: $negative;
        color: $negative;
    }
}


// Reimbursement Claim Status

.claim-status {
    background: $secondary;
    max-width: 45rem;
    margin: 0 auto 5rem;
    padding: 1rem 1.5rem 0.5rem;
    border-radius: 0.25rem;
    text-align: center;
    font-size: 90%;
    color: $white;
}


// Content

#user + #content {
    margin-top: 3.75rem;
}

#content {
    margin-left: 25%;
    padding: 2rem;

    &.medium {
        .main-container {
            max-width: 60rem;
        }
    }

    &.large {
        .main-container {
            max-width: 80rem;
        }
    }

    .main-container {
        max-width: 30rem;
        margin: 0 auto;
    }

    .loggedin {
        strong {
            margin-left: 1.5rem;

            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}

.errors {
    text-align: center;
    color: $negative;
}

.optional {
    display: none;

    .active {
        display: block;
    }
}

.cms-content {
    margin-bottom: 3rem;
}

img {
    display: block;
    width: 100%;
}


// Footer

footer {
    background: darken($white, 3%);
    margin-left: 25%;
    padding: 1rem;
    border-top: 1px solid $mediumgrey;
    text-align: center;
    font-size: 80%;

    &.pinned {
        position: absolute;
        right: 0; bottom: 0; left: 0;
    }

    p {
        margin-bottom: 0;
    }

    a {
        color: darken($mediumgrey, 20%);

        &:hover {
            color: $dark;
        }
    }
}


// Auth Screens

#auth {
    position: absolute;
    top: 50%; left: 50%;
    width: 23.5rem;
    padding-top: 1rem;
    transform: translate(-50%, -50%);

    &.static {
        top: 1rem;
        transform: translate(-50%, 0);

        .content {
            border-top-left-radius: 0;
        }

        .tab {
            width: 100%;
            text-align: center;
        }
    }

    .content {
        background: darken($white, 3%);
        padding: 2rem;
        border-radius: 0.5rem;

        #logo {
            display: block;
            width: 50%;
            margin: 0 auto 2rem;

            img {
                display: block;
                width: 100%;
            }
        }

        p {
            font-size: 95%;
        }

        label {
            display: none;
        }

        input {
            margin-top: 1em;

            &:first-of-type {
                margin-top: 0;
            }
        }

        .actions {
            margin-top: 1em;
        }

        .forgot {
            float: left;
            padding: 0.625rem 0.25rem;
            font-size: 70%;
            color: lighten($darkgrey, 20%);

            &:hover {
                color: $secondary;
            }
        }

        button {
            font-size: 90%;
        }
    }

    footer {
        background: transparent;
        margin: 0;
        padding: 1.25rem;
        border: 0 none;
        font-size: 70%;
        color: rgba($white, 0.5);

        p {
            margin: 0;
        }

        a {
            color: rgba($white, 0.75);

            &:hover {
                color: $white;
            }
        }
    }
}


// Modals

.overlay,
.mobile-overlay {
    display: none;
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;

    &.active {
        display: block;
    }
}

.modal {
    display: none;
    position: fixed;
    top: 50%; right: 0; left: 25%;
    background: $white;
    width: 90%;
    max-width: 40rem;
    margin: auto;
    padding: 2rem;
    transform: translate(0, -50%);
    border-radius: 0.5rem;
    text-align: center;
    font-size: 100%;
    z-index: 100;

    &.active {
        display: block;
    }

    .close {
        position: absolute;
        top: 0.5rem; right: 0.75rem;
        font-weight: bold;
        color: darken($mediumgrey, 5%);

        &:hover,
        &:focus {
            color: $black;
        }
    }

    h3 {
        margin-bottom: 2rem;
    }

    .button,
    a.button.red {
        display: inline-block;
        margin: 1rem 0.5rem 0;
    }

    .pseudo-table {
        margin: 0 auto;

        p {
            text-align: right;

            &.mobile-label {
                text-align: left;
            }
        }
    }
}


// Pagination

.pagination-section {
    float: right;
    padding-bottom: 0.625rem;
    text-align: center;
    font-size: 80%;

    .pagination {
        display: flex;
        margin: 0;
        padding: 0;
        justify-content: center;

        li {
            display: block;
            list-style: none;

            &:hover:not(.active) {
                a, span {
                    background-color: $lightgrey;
                }
            }

            &.disabled {
                &:hover {
                    a {
                        background: none;
                        cursor: default;
                    }
                }

                a {
                    color: $mediumgrey;
                }
            }

            &.active {
                a, span {
                    background-color: $tertiary;
                    border: 1px solid $tertiary;
                    color: $white;
                }
            }

            a, span {
                float: left;
                margin: 0 0.125rem;
                padding: 0.5rem 1rem;
                transition: background-color .3s;
                border: 1px solid $lightgrey;
                text-decoration: none;
                border-radius: 2px;
                color: $black;
            }
        }
    }
}


// Pseudo Table

.pseudo-table {
    display: table;
    width: 100%;

    &.details {
        .table-row {
            .mobile-label {
                display: table-cell;
            }
        }
    }

    &.v-stripe {
        .table-row {
            p {
                &:nth-of-type(4n) {
                    background: lighten($lightgrey, 4%);
                }
            }
        }
    }

    &.h-stripe {
        .table-row {
            &:nth-child(2n+1) {
                background: lighten($lightgrey, 4%);
            }
        }
    }

    .table-head {
        display: table-row;
        width: 100%;

        h5 {
            display: table-cell;
            background: $tertiary;
            width: auto;
            padding: 0.5rem 0.75rem;
            vertical-align: bottom;
            text-align: center;
            font-weight: 500;
            font-size: 80%;
            color: $white;
        }
    }

    .table-row {
        display: table-row;
        width: 100%;

        &:last-child {
            p {
                border-bottom: 0 none;
            }
        }

        &.highlight-row {
            &:hover {
                background: lighten($tertiary, 23%);
            }
        }

        p {
            display: table-cell;
            margin: 0;
            padding: 1rem 0.75rem;
            border-bottom: 1px solid $lightgrey;
            vertical-align: middle;
            font-size: 90%;

            &:first-of-type() {
                border-top: 0 none;
            }

            &.input {
                padding: 0.5rem 0.25rem 0;
            }
        }

        .mobile-label {
            display: none;

            &.input {
                padding: 0.75rem 0;
            }
        }

        .amount {
            width: 7rem;
        }

        .date {
            width: 6.5rem;
            text-align: center;
        }

        .center {
            text-align: center;
        }

        .view-button {
            width: 100%;
            text-align: right;
        }

        .view {
            width: 2.75rem;
            padding: 0;
            text-align: center;
            color: darken($mediumgrey, 20%);

            &:hover {
                cursor: pointer;
                color: $dark;

                a {
                    color: $dark;
                }
            }

            a {
                display: block;
                padding: 1rem 0;
                border-bottom: 0 none;
                color: darken($mediumgrey, 20%);
            }
        }
    }

    .sub-total {
        p {
            border-bottom: 4px double $mediumgrey;
            font-weight: bold;
        }
    }

    a {
        border-bottom: 1px solid;

        &:hover {
            border-bottom: 0 none;
            color: $tertiary;
        }

        &.button {
            border-bottom-color: $dark;
        }
    }
}

.amount {
    padding-left: 1rem;
    white-space: nowrap;
    text-align: right;

    &::before {
        content: '$';
        float: left;
        margin-right: 0.25rem;
        color: $dark;
    }

    &.input {
        &:before {
            content: none;
        }
    }

    input {
        text-align: right;
    }
}

.footnote {
    margin: 3rem 0 0 0;
    padding: 1rem 0;
    border-top: 1px solid $lightgrey;
    text-align: center;
    font-size: 75%;
}

.note {
    font-size: 80%;
}


// Data list

.data-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 0;
    text-align: left;

    dt {
        width: 50%;
        margin: 0 0 0.5rem;
        padding: 0;
        font-weight: bold;
        font-size: 95%;
        clear: left;
    }

    dd {
        width: 50%;
        margin: 0 0 0.5rem;
        padding: 0;
        font-size: 95%;
    }
}


// Dividers

.divider {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $lightgrey;
}


// Page Specific

// -- Login

#login {
    background: $secondary;

    .content {
        border-top-right-radius: 0;
    }

    .tab {
        display: inline-block;
        position: absolute;
        top: 1rem; right: 0;
        background: $button-secondary;
        padding: 0.25rem 0.75rem;
        transform: translate(0, -100%);
        border-top-right-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
        font-weight: 500;
        font-size: 70%;
        color: $dark;

        &:after {
            content: '';
            display: block;
            position: absolute;
            right: 0; bottom: 0; left: 0;
            background: rgba($black, 0.1);
            height: 2px;
        }

        &:hover {
            background: lighten($button-secondary, 10%);
        }
    }

    #auth {
        .description {
            margin-bottom: 2rem;
            text-align: left;
            font-size: 80%;
        }
    }

    .login-help {
        margin: 2rem 0 0;
        padding: 1rem 0 0;
        border-top: 1px solid $mediumgrey;
        text-align: left;
        font-size: 80%;

        p:last-child {
            margin-bottom: 0;
        }
    }
}


// -- Forgotten Password

#password-reset {
    background: $dark;

    .nav {
        display: inline-block;
        margin: 0.75rem auto 0;
        clear: both;
    }

    #password-validate {
        margin-top: 2rem;
    }
}

// -- Home / Landing

#home {
    h1 {
        margin-bottom: 0;
        text-align: center;
    }

    h2 {
        text-align: center;
    }

    .pdfs {
        display: flex;
        width: 100%;
        margin: 5rem auto 0;
        justify-content: center;
        align-items: baseline;
        flex-wrap: wrap;

        & + .pdfs {
            margin-top: 0;
        }

        .pdf {
            margin-left: 1rem;

            &:first-of-type {
                margin-left: 0;
            }
        }
    }

    .download-pdf {
        display: flex;
        max-width: 23rem;
        margin: 0 0 1rem;
        padding: 0.75rem 1rem;
        justify-content: center;
        border: 2px solid $button-primary;
        border-radius: 0.25rem;
        align-items: center;
        font-size: 190%;
        line-height: 1;
        color: $button-primary;

        &.amendment-form {
            max-width: 25rem;
        }

        &:hover {
            background: $button-primary;
            border-color: $button-primary;
            color: $white;
        }

        i {
            display: inline-block;
            margin-right: 1rem;
            vertical-align: middle;
            font-size: 140%;
        }

        span {
            display: inline-block;
            vertical-align: middle;

            small {
                display: block;
                margin: 0.25rem 0;
                font-size: 45%;
            }

            strong {
                text-transform: uppercase;
            }
        }

        img {
            display: block;
            width: 100%;
        }
    }

    .rec-message {
        max-width: 20rem;
        margin: 0 auto;
        text-align: center;
        font-size: 85%;

        .return-btn {
            margin-top: 1rem;
        }
    }
}

#landing-nav {
    margin-top: 2rem;
    text-align: center;
    font-size: 0;

    .nav-section {
        display: inline-block;
        width: 33%;
        max-width: 20rem;
        padding: 0.5rem 1.25rem;
        border-right: 1px solid $mediumgrey;
        vertical-align: top;
        font-size: 1rem;

        &:last-of-type {
            border-right: 0 none;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                margin: 0;
                padding: 0.25rem 0;
                list-style: none;
                font-size: 95%;
            }

            ul {
                display: none;
            }
        }
    }
}


// -- Salary Package Card Offer

.landing-page-hero {
    margin: 1rem 0;
}

#special-offers-form {
    .data-list {
        margin: 0 auto;

        dt {
            padding-right: 0.5rem;
            text-align: right;
        }

        dd {
            padding-left: 0.5rem;
        }
    }

    .radiochecks {
        margin: 0 auto;

        label {
            display: flex;
            margin: 1rem 0 0.25rem;
            align-items: center;

            span {
                padding-left: 0.75rem;
                font-size: 135%;
            }
        }
    }
}


// -- Submit Claims

#submit-claims {
    #submit-claims-form {
        .set {
            padding: 0.5rem 1rem 1rem;
            border: 1px solid $primary;
            border-radius: 0.25rem;

            legend {
                margin: 0 auto;
                padding: 0 0.5rem;
            }
        }

        .claims-uploading {
            background: rgba($positive, 0.1);
            margin: 1.5rem auto;
            padding: 1em;
            border: 1px solid $positive;
            border-radius: 0.25rem;
            text-align: center;
        }
    }
}


// -- Claims Summary

.priorUnspentFunds {
    max-width: 18rem;
    margin: 0 auto;
    font-size: 110%;

    .amount-label {
        width: 11rem;
    }

    .amount {
        width: 7rem;
    }
}

.vehicle-panel {
    margin-top: 2rem;

    .rego {
        float: left;
        width: auto;
    }

    .funds-available-now {
        float: right;
        width: auto;
        margin: 0.375rem 0 0;

        span {
            color: darken($positive, 20%);

            &::before {
                content: '$';
                margin-right: 0.25rem;
            }
        }
    }
}

#claims-summary-table {
    .benefit {}

    .prior-unspent-funds {
        min-width: 10%;
    }

    .amount-budgeted {
        min-width: 10%;
    }

    .claims-submitted {
        min-width: 10%;
    }

    .payments {
        min-width: 10%;
    }

    .further-claims-required {
        min-width: 10%;
    }
}

.card-container {
    margin-bottom: 1rem;

    .card {
        display: flex;
        background: darken($white, 3%);
        margin-top: 1.5rem;
        padding-bottom: 0.5rem;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        align-items: flex-start;
        flex-wrap: wrap;
        font-size: 90%;

        &.total {
            background: darken($white, 5%);
            justify-content: flex-end;
            border-radius: 3px;

            .col {
                &:first-child {
                    border-left: 0 none;
                }
            }
        }

        .header {
            display: flex;
            background: $tertiary;
            width: 100%;
            padding: 0.5rem 0.75rem;
            justify-content: space-between;
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
            flex-wrap: wrap;
            color: $white;

            :first-child {
                margin: 0;
                font-size: 95%;
            }

            :last-child {
                margin: 0;
                font-size: 95%;
            }
        }

        .col {
            display: table;
            width: 33.33%;
            margin: 0.5rem 0 0;
            padding: 0.25rem 1rem;
            border-left: 1px solid darken($white, 15%);
            font-size: 90%;

            &:nth-child(2) {
                border-left: 0 none;
            }

            .group {
                display: table-row;
                margin: 0;

                label {
                    display: table-cell;
                    margin: 0;
                    padding: 0.25rem 0;
                    white-space: nowrap;
                    font-weight: 500;
                }

                p {
                    display: table-cell;
                    margin: 0;
                    padding: 0.25rem 0;
                    text-align: right;

                    &.amount {
                        width: 5rem;
                    }

                    a {
                        border-bottom: 1px solid;
                    }
                }
            }
        }
    }
}


// -- Your Package

#report-selection-form {
    margin-bottom: 3rem;
    text-align: left;

    select {
        display: inline-block;
        width: 100%;
        max-width: 20rem;
        border-right: 0 none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    button {
        margin-top: 0;
        border-top: 1px solid $button-positive;
        border-bottom: 1px solid $button-positive;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .download-pdf.button {
        float: right;
        margin: 1px 0 0 0;
        font-size: 80%;
    }
}

#benefit-details-table {
    margin-bottom: 2rem;
    padding: 1rem 0 0 0;
    border-bottom: 1px solid $lightgrey;
}

#motor-details {
    max-width: 20rem;
    margin: 0 auto 2rem;

    h4 {
        text-align: center;
    }
}


#benefit-item-details {
    max-width: 25rem;
}


// -- Amend Package

#amend-package-form {
    .personal-details {
        margin: 0 auto;

        #change-personal-details {
            display: none;
        }
    }

    .manage-benefits {
        margin-top: 5rem;
    }

    .motor-vehicle-benefits {
        margin-top: 5rem;

        .statutory-rate {
            display: none;
        }

        .addVehicle {
            float: right;
            margin: 1rem 0.5rem 0.5rem;
            font-weight: bold;
            font-size: 80%;
            color: lighten($darkgrey, 20%);

            &:hover {
                color: $tertiary;
            }
        }
    }

    .benefits-table {
        margin-top: 2rem;

        + .buttons {
            margin-top: 0;
        }
    }

    .addRow {
        float: right;
        margin: 1rem 0.5rem 0.5rem;
        font-weight: bold;
        font-size: 80%;
        color: lighten($darkgrey, 20%);

        &:hover {
            color: $tertiary;
        }
    }

    .other-information-section {
        margin-top: 5rem;
    }
}


// -- Package Statements

#employee-details {
    float: left;
    width: auto;
    max-width: 45%;
}

#balance-summary {
    float: right;
    width: auto;
    max-width: 45%;
}

#statement-body {
    margin-top: 2rem;
}

.download-csv {
    float: left;
    margin: 0;
    text-align: center;
    font-size: 90%;
    clear: both;
}

#package-statement-table {
    .payments {
        font-weight: 500;
        color: darken($button-positive, 10%);

        &:before {
            color: darken($button-positive, 10%);
        }
    }

    .deductions {
        font-weight: 500;
        color: $negative;

        &:before {
            color: $negative;
        }
    }
}

#package-transaction-balance {
    max-width: 20rem;
    margin: 0 auto;
    text-align: left;

    .table-row:first-child {
        border-top: 0 none;
    }
}

#itcs-and-charges {
    max-width: 25rem;
}

.details-table {
    display: table;
    text-align: left;

    .details-table-row {
        display: table-row;

        .details-table-col {
            display: table-cell;

            &.padded-item {
                padding-left: 1.25rem;
            }
        }
    }
}


// -- Pay My Rego

#pay-rego {
    form {
        margin-top: 5rem;

        .rego-renewal-details {
            margin-bottom: 2.5rem;
            padding-bottom: 2.5rem;
            border-bottom: 1px solid $mediumgrey;
        }

        .rego-renewal-documents {
            margin-bottom: 2.5rem;
            padding: 0 1.5rem;
            border: 1px solid $mediumgrey;
            border-radius: 0.5rem;

            legend {
                padding: 0 1rem;
            }

            p {
                font-size: 90%;
            }
        }
    }
}

.rego-confirmation {
    text-align: center;

    .receipt-number {
        margin: 2.5rem 0;
    }
}

section#submit-claims,
section#pay-rego {
    h3 {
        text-align: center;
    }

    form.uploader {
        legend {
            margin: 0;
            padding: 0 1em;
            text-align: center;
            font-size: 130%;
        }

        div.claims-uploading {
            display: none;
            margin: 0.5em 1em;
            text-align: center;

            p {
                margin: 0;
                font-size: 0.8em;
            }
        }

        fieldset.buttons {
            button {
                transition: width 1s, background-size 0.2s;

                &.error {
                    background: $negative;
                }
            }

            p.fail-error {
                margin: 0.5rem 0 0;
                font-size: 0.85em;
                color: $negative;
            }

            p.cancel {
                display: none;
            }
        }

        &.uploading {
            div.claims-uploading {
                p.uploaded {
                    display: none;
                }

                fieldset.buttons {
                    padding: 0.5em 1em;
                }
            }

            .set {
                background: rgba($neutral, 0.15);
                padding: 0 1rem 1rem;
                border: 1px solid $neutral;
                border-radius: 3px;
            }

            fieldset.buttons {
                margin-top: 0;
                padding: 0.5em 1em;
                text-align: center;

                button {
                    background-color: lighten($mediumgrey, 10%);
                    background-image: url('/img/claims-uploading-bg.png');
                    background-repeat: no-repeat;
                    width: 100%;
                    margin: 0;
                    color: $darkgrey;

                    &.error {
                        background: $negative !important;
                        color: $white;
                    }
                }

                p.cancel {
                    display: block;
                    display: none;
                }
            }
        }

        &.uploaded {
            .set {
                background: rgba($positive, 0.15);
                padding: 0 1rem 1rem;
                border: 1px solid $positive;
                border-radius: 3px;
            }

            div.claims-uploading {
                p.uploaded {
                    display: block;
                    color: green;
                }
            }

            fieldset.buttons {
                display: none;
            }
        }
    }

    fieldset#claims-addanother {
        display: none;
        text-align: center;
        border: none;

        a.button {
            margin-bottom: 1em;
        }

        p#uploading-status {
            text-align: center;
            font-size: 0.85em;
        }

        p#uploading-status {
            display: none;
        }
    }
}

#pay-rego {
    legend {
        text-align: center;
    }

    fieldset.rego-options {
        text-align: center;

        input {
            display: none;
        }

        label {
            display: inline-block;
            width: 45%;
            vertical-align: top;
            text-align: center;
            cursor: pointer;

            span.icon {
                display: block;
                max-width: 70%;
                margin: 1em auto;
                padding: 1em;
                border-radius: 20em;
                border: 1px solid lighten(#ffc13b, 15%);
                transition: background 0.5s;

                img {
                    max-width: 100%;
                }
            }

            span.text {
                display: block;
            }

            &:hover {
                span.icon {
                    background-color: lighten(#ffc13b, 15%);
                }
            }

            &.active {
                span.icon {
                    background-color: darken(#ffc13b, 0%);
                }
            }
        }

        a.button,
        span.error {
            display: block;
            margin-top: 1em;
            text-align: center;
        }
    }

    fieldset.bpay-claim-file-block {
        a.button {
            margin: 0 auto;
        }

        div.progress {
            display: block;
            display: none;
            width: 100%;
            overflow: hidden;

            > i {
                display: block;
                width: 100%;
                padding: 0;
                border-radius: 1em;
                transition: width 0.25s;
                white-space: nowrap;
                font-style: normal;
            }

            &.error {
                float: none;
                background: transparent;
                background: #c55a5a;
                margin-top: 0;
                font-size: 1em;

                i {
                    background: transparent;
                }
            }
        }

        &.uploaded {
            margin: 0;
            padding: 0.5em 0.25em;
            border-top: 1px solid darken($lightgrey, 5%);
            text-align: left;

            &:first-of-type {
                border-top: 0 none;
            }

            a.remove {
                float: right;
                width: 22px;
                border-radius: 2px;
                text-decoration: none;
                text-align: center;
                color: $negative;

                &:hover {
                    background: $negative;
                    color: #fff;
                }
            }
        }

        &:not(.uploaded) {
            margin-top: 1em;
        }
    }

    div#bpay-claim-addanother {
        display: none;
        margin-bottom: 1em;
        text-align: center;
    }

    p.sizewarning {
        text-align: center;
        font-size: 0.85em;
    }

    .manufacture-year {
        display: none;
    }
}

.qld-message {
    display: block;
    background: $negative;
    width: 100%;
    margin: 0;
    padding: 0.4rem 0.65rem;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    text-align: left;
    font-size: 70%;
    color: $white;
}


// -- Fuel Card History

#filters {
    float: left;
    padding-bottom: 0.625rem;

    label {
        display: inline-block;
        width: auto;
        margin: 0 0.5rem 0 0.125rem;
    }

    #rego {
        display: inline-block;
        width: auto;
        font-size: 85%;
    }
}

#fuel-card-history-table {
    margin-bottom: 2rem;

    .table-head {
        .amount {
            &::before {
                content: none;
            }
        }
    }

    .table-row {
        .registration {
            width: 6.5rem;
            text-align: center;
        }
    }
}

.transaction-detail {
    margin-bottom: 0.5rem;
}


// -- Vehicle Unavailability

.unavailability-details {
    display: none;
    @include grid-column(12, $gutterless);
}

#unavailability-confirmation {
    .cms-content {
        text-align: center;
    }
}


// -- Contact Details

#contact-details-form {
    .postal-address {
        margin-top: 2.25rem;
    }

    .same-as-postal {
        margin: 1rem 0 0;
    }

    .residential-address {
        margin-bottom: 0;
    }
}


// -- Change Password

#change-password-form {
    #password-validate {
        margin-bottom: 0;
        padding-top: 2rem;
    }
}


// -- Contact

#contact-us {
    background: $dark;

    // #auth {
    //     top: 0;
    //     margin-top: 0.625rem;
    //     transform: translate(-50%, 0);
    // }

    .contact-details {
        margin-bottom: 1rem;
        text-align: center;
        font-size: 90%;
    }

    #contact-us-form {
        max-width: 25rem;
        margin: 0 auto;

        label {
            display: block;

            &:first-child {
                margin-top: 0;
            }
        }

        input {
            margin-top: 0;
        }
    }
}


// PDF

.pdf {
    #content {
        padding: 0;
    }

    .pdf-header {
        margin-bottom: 2rem;

        img {
            float: right;
            display: block;
            width: 120px;
            vertical-align: middle;
        }

        h2 {
            display: block;
            margin: -5px 0 0 0px;
            vertical-align: middle;
            line-height: 1;
        }
    }

    table {
        width: 100%;
        margin: 0;

        &#package-statement-table {
            margin-top: 2rem;
        }

        thead {
            tr {
                th {
                    background: #84baf3;
                    padding: 5px 10px 10px;
                    vertical-align: middle;
                    font-weight: 500;
                    font-size: 13px;
                    color: #fff;

                    &.center {
                        text-align: center;
                    }
                }
            }
        }

        tbody {
            tr {
                &:first-child {
                    td {
                        border-top: 0 none;
                    }
                }

                td {
                    padding: 10px;
                    border-top: 1px solid #eee;
                    vertical-align: middle;
                    font-size: 12px;

                    &.date {
                        text-align: center;
                    }

                    &.currency {
                        padding: 0;
                    }

                    &.amount {
                        text-align: right;

                        &:before {
                            content: none;
                            float: none;
                            margin-right: 10px;
                        }
                    }

                    &.empty {
                        border-top: 0 none;
                    }
                }
            }
        }
    }

    .pdf-footer {
        position: fixed;
        bottom: 4px;
        text-align: center;

        p {
            font-size: 11px;
        }
    }
}


// Responsive

@media only screen and (min-width: 1280px) {
    #sidebar {
        width: 20rem;
    }

    #user,
    #announcement,
    #content,
    footer {
        margin-left: 20rem;
    }

    .modal {
        left: 20rem;
    }
}

@media only screen and (max-width: 1024px) {
    #statement-header {
        p {
            font-size: 90%;
        }
    }

    #motor-details {
        font-size: 90%;
    }

    .payroll-deduction-panel {
        font-size: 90%;
    }

    #home {
        .pdfs {
            flex-direction: column;

            .pdf {
                margin: 0 auto;

                &:first-of-type {
                    margin-left: auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 940px) {
    nav {
        font-size: 90%;
    }

    #content {
        padding: 1.5rem;
        font-size: 90%;
    }

    #landing-nav {
        .nav-section {
            display: block;
            width: 20rem;
            max-width: 100%;
            height: auto !important;
            margin: 1rem auto 0;
            padding-top: 1.5rem;
            border-top: 1px solid $mediumgrey;
            border-right: 0 none;

            &:first-child {
                border-top: 0 none;
            }
        }
    }

    .contact-details {
        max-width: 100%;
    }

    .contact-form {
        display: block;
        max-width: 100%;
    }

    #package-detail-panel {
        .payroll-deduction-panel {
            .payroll-details {
                width: 100%;
            }

            .cost-details {
                float: left;
                width: 100%;
                margin-top: 2rem;
            }
        }
    }

    .modal {
        max-width: 65%;
    }

    #report-selection-form {
        select {
            max-width: 18rem;
        }
    }
}

@media only screen and (max-width: 850px) {
    #statement-header {
        .employee-details {
            float: left;
            width: 100%;
            padding: 0.6rem 0.3rem;
            border-bottom: 1px solid $lightgrey;
        }

        .balance-summary {
            float: left;
            width: 100%;
            margin-bottom: 1rem;
            padding: 0.6rem 0.3rem;
            border-bottom: 1px solid $lightgrey;
        }
    }

    .details-table {
        width: 100%;

        .details-table-row {
            .details-table-col {
                width: 50%;
            }
        }
    }

    #package-detail-panel {
        .benefit-detail-panel {
            .details-table {
                width: 100%;

                .details-table-row {
                    .details-table-col {
                        width: 50%;
                    }
                }
            }

            .employee-details {
                width: 100%;
            }

            .benefit-details {
                float: left;
                margin: 1rem 0;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    #sidebar {
        position: relative;
        width: 100%;

        header {
            min-height: 0;
            z-index: 2;

            #logo {
                float: left;
                position: relative;
                display: inline-block;
                top: auto; left: auto;
                max-width: 5rem;
                margin: 0.75em;
                transform: none;
            }

            #account-icon,
            #menutoggle {
                display: block;
            }
        }

        nav {
            background: $dark;
            position: absolute;
            top: 3.5rem; bottom: auto;
            width: 100%;
            transition: transform 300ms ease 0ms;
            transform: translateY(-100%);
            z-index: 1;
        }
    }

    #user {
        background: $dark;
        position: absolute;
        top: auto; bottom: auto;
        height: auto;
        margin-left: 0;
        transition: transform 300ms ease 0ms;
        transform: translateY(-100%);
        text-align: left;
        color: $white;
        z-index: 1;

        .current-user {
            font-size: 90%;

            strong {
                display: block;
                margin-left: 0;
            }
        }

        .logout {
            position: absolute;
            top: 50%; right: 1rem;
            margin-left: 0;
            transform: translateY(-50%);
        }
    }

    #announcement {
        margin: 0;
    }

    #user + #content {
        margin-top: 0;
    }

    #content {
        width: 100%;
        margin-left: 0;
        padding: 2rem;
    }

    .deduction-method,
    .deduction-period {
        .radiochecks {
            margin-left: 0.5rem;
        }
    }

    form {
        .buttons {
            &.nav {
                button,
                a.button {
                    font-size: 80%;
                }
            }
        }

        fieldset {
            &.buttons {
                &.nav {
                    margin: 4rem -1rem -1rem;
                }
            }
        }
    }

    footer {
        width: 100%;
        margin-left: 0;
        clear: left;
    }

    #login {
        nav {
            position: absolute;
            top: 6.1rem;
            margin-top: 0;
        }

        #content {
            width: 100%;
        }
    }

    #login-form {
        margin: 4rem auto;
    }

    .modal {
        left: 50%;
        max-width: 90%;
        padding: 1.5rem 1rem 1rem;
        transform: translate(-50%, -50%);
    }

    #employee-details {
        width: 100%;
        max-width: 100%;

        .table-row {
            p {
                text-align: right;

                &.mobile-label {
                    text-align: left;
                }
            }

            &:last-child {
                p {
                    border-bottom: 1px solid $lightgrey;
                }
            }
        }
    }

    #balance-summary {
        width: 100%;
        max-width: 100%;
    }
}

@media only screen and (max-width: 700px) {
}

@media only screen and (max-width: 650px) {
}

@media only screen and (max-width: 568px) {
    #content {
        padding: 1rem 1rem 2rem;
    }

    h1 {
        margin-bottom: 0.5em;
    }

    .full {
        @include grid-column(12, $gutterless);

        .half {
            @include grid-column(12, $gutterless);
        }
    }

    .half {
        @include grid-column(12, $gutterless);
    }

    form {
        fieldset {
            legend {
                font-size: 130%;
            }
        }

        .radiochecks {
            padding: 0;
        }
    }

    .contact-details {
        @include grid-column(12);
    }

    .contact-form {
        @include grid-column(12);
    }

    .deduction-method,
    .deduction-period {
        padding: 0 1rem;
    }

    .files {
        li {
            width: 33.3%;
        }
    }

    form {
        fieldset {
            &.confirmation {
                padding: 3rem 1rem 0;
            }
        }

        .buttons {
            &.nav {
                button,
                a.button {
                    padding: 0.5rem 1.5rem;
                }
            }
        }
    }

    .pseudo-table {
        &.details {
            .table-row {
                p {
                    padding: 0 0.25rem;

                    &.mobile-label {
                        &.amount-label {
                            width: calc(100% - 5.5rem);
                        }
                    }
                }

                .amount {
                    width: 5.5rem;
                }
            }
        }

        &.v-stripe {
            .table-row {
                p {
                    &:nth-of-type(4n) {
                        background: none;
                    }
                }
            }
        }

        &.h-stripe {
            .table-row {
                &:nth-child(2n+1) {
                    background: none;
                }
            }
        }

        .table-head {
            display: none;
        }

        .table-row {
            display: block;
            padding: 1rem 0;
            border-top: 1px solid $lightgrey;

            p {
                float: left;
                display: block;
                width: 50%;
                padding: 0.25rem 0.5rem;
                border: 0 none;
                text-align: right;

                &.input {
                    padding: 0.25rem;
                }

                &.mobile-label {
                    display: block;
                    text-align: left;

                    &.amount-label {
                        width: calc(100% - 7rem);
                    }
                }

                &.view-button {
                    text-align: right;
                }
            }

            .date {
                width: 50%;
                text-align: right;
            }

            .center {
                text-align: right;
            }

            .view {
                display: none;
            }

            &.sub-total {
                p {
                    font-weight: 300;
                }
            }
        }
    }

    .download-csv {
        float: none;
        margin-bottom: 1rem;
        font-size: 85%;

        a {
            padding: 0.325rem 1rem;
        }
    }

    .pagination-section {
        float: none;

        .pagination {
            font-size: 90%;

            li {
                a,span {
                    float: left;
                    margin: 0 0.125rem;
                    padding: 0.375rem 0.75rem;
                    border: 1px solid lighten($mediumgrey, 6.5%);
                    transition: background-color .3s;
                    text-decoration: none;
                    color: $black;

                    &.active {
                        background-color: $tertiary;
                        border: 1px solid $tertiary;
                        color: $white;
                    }

                    &:hover:not(.active) {
                        background-color: lighten($mediumgrey, 6.5%);
                    }
                }
            }
        }
    }

    .vehicle-panel {
        .rego {
            float: none;
            width: 100%;
        }

        .funds-available-now {
            float: none;
            width: 100%;
            margin: 0;
            padding: 1rem 0.5rem;
            border-top: 1px solid $lightgrey;

            span {
                float: right;
                width: 6rem;
                text-align: right;

                &::before {
                    float: left;
                }
            }
        }
    }

    #claims-summary-table {
        .table-row {
            p {
                &:first-child {
                    display: none;
                }
            }
        }

        .benefit {
            width: 100%;
            font-weight: bold;
            text-align: left;
        }

        .benefit-items {
            width: 100%;
            padding: 0.25rem 0;
            font-weight: bold;
            text-align: left;
        }
    }

    #benefit-details-table {
        .benefit-items {
            width: 100%;
            font-weight: bold;
            text-align: left;
        }
    }

    #payroll-deduction {
        margin-bottom: 2rem;
    }

    #amend-package-form {
        .available-vehicles {
            label {
                float: left;
                width: 50%;
                margin: 1.125rem 0;
            }

            .vehicle {
                float: left;
                width: 50%;
                margin-top: 0.5rem;
                clear: none;
            }
        }

        .statutory-rate {
            label {
                float: left;
                width: 50%;
                margin: 1.125rem 0;
            }

            .change_rate {
                float: left;
                width: 50%;
                margin-top: 0.5rem;
                clear: none;
            }
        }

        .manage-benefits,
        .motor-vehicle-benefits {
            .card {
                padding: 0.5rem 1rem 0.5rem;

                .motor-vehicle-benefits-table {
                    margin-top: 0.75rem;
                }
            }
        }
    }

    #employee-details {
        .table-row {
            &:first-child {
                border-top: 0 none;
            }

            &:last-child {
                p {
                    border-bottom: 0 none;
                }
            }
        }
    }

    #package-statement-table {
        .table-row {
            .date {
                width: 100%;
                padding: 0.25rem 0.5rem 0;
                text-align: left;
                font-size: 80%;
                color: lighten($darkgrey, 30%);
            }

            .description {
                width: 100%;
                font-weight: bold;
                text-align: left;
            }

            .payments {
                width: 7rem;
            }

            .deductions {
                width: 7rem;
            }

            .balance {
                width: 7rem;
            }

            .mobile-label {
                width: 100%;
                max-width: calc(100% - 7rem);
            }
        }
    }

    .card-container {
        .card {
            font-size: 100%;

            .col {
                width: 100%;
                padding: 0 1rem;
                border: 0 none;
            }
        }
    }

    #report-selection-form {
        select {
            display: inline-block;
            width: calc(100% - 4.75rem);
            max-width: 100%;
        }

        .download-pdf.button {
            float: none;
            display: block;
            width: 100%;
            max-width: 10rem;
            margin: 1rem auto 0;
        }
    }

    #filters {
        float: none;
        margin-bottom: 1rem;
    }

    #fuel-card-history-table {
        .table-row {
            .registration {
                width: 50%;
                text-align: right;
            }
        }
    }

    #contact-details-form {
        fieldset.personal-details {
            margin-bottom: 2rem;
        }
    }
}

@media only screen and (max-width: 450px) {
    #login-form {
        margin: 0 auto;
    }

    #auth {
        top: 50%;
        width: 18.75rem;
        transform: translate(-50%, -50%);

        .content {
            padding: 1.25rem;

            #logo {
                margin-bottom: 1.5rem;
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    #user {
        text-align: center;

        .current-user {
            margin: 0;
        }

        .logout {
            float: none;
            display: block;
            position: relative;
            top: auto; right: auto;
            margin-top: 1rem;
            transform: none;
        }
    }

    #home {
        .download-pdf {
            font-size: 170%;

            &.amendment-form {
                small {
                    margin-bottom: 0;
                }

                strong {
                    font-size: 80%;
                }
            }
        }
    }

    #landing-nav {
        .nav-section {
            width: 100%;
        }
    }

    .files {
        li {
            width: 50%;
        }
    }

    form {
        .buttons {
            &.nav {
                button,
                a.button {
                    float: none;
                    display: block;
                    width: 100%;
                    margin: 0.5rem 0 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .pseudo-table {
        .table-row {
            padding: 0.5rem 0;

            p {
                &.benefit {
                    padding: 0.25rem 0.5rem;
                }
            }
        }
    }

    .vehicle-panel {
        .funds-available-now {
            padding: 0.75rem 0.5rem;
        }
    }

    .card-container {
        .card {
            .header {
                > :first-child {
                    width: 100%;
                    text-align: right;

                    strong {
                        float: left;
                    }
                }

                > :last-child {
                    width: 100%;
                    text-align: right;

                    strong {
                        float: left;
                    }
                }
            }
        }
    }

    #package-detail-panel {
        .package-select-panel {
            .package-form {
                #report-selection-form {
                    .report-select {
                        select {
                            width: 67%;
                        }
                    }
                }
            }
        }
    }

    .contact-form {
        form {
            .recaptcha {
                transform: scale(0.85);
                transform-origin: 0 0;
            }
        }
    }

    .statements-pseudo-table {
        .table-row {
            display: block;

            p {
                font-size: 85%;
            }
        }
    }
}

@media only screen and (max-width: 320px) {
    .third {
        @include grid-column(12);
    }

    .statements-pseudo-table {
        .table-row {
            display: block;

            p {
                font-size: 80%;
            }
        }
    }
}